import React from "react"
import Img, { FluidObject } from "gatsby-image"

import Column from "../Column"
import Link from "../Link"
import Row from "../Row"
import Wrapper from "../Wrapper"

import styles from "./index.module.scss"
import { Body, H3 } from "../Typography"

type PropsType = {
  img: FluidObject
  imgAlt: string
  title: string
  description: string
  cta: {
    text: string
    url: string
  }
}

const AboutSection = ({ img, imgAlt, title, description, cta }: PropsType) => {
  return (
    <section className={styles.aboutSection}>
      <Wrapper>
        <Row center>
          <Column mobile={8} desktop={14} className={styles.aboutInner}>
            <Row spaceBetween middle>
              <Column mobile={8} desktop={3}>
                <Img fluid={img} alt={imgAlt} className={styles.aboutImg} />
              </Column>
              <Column mobile={8} desktop={13}>
                <H3 component="h2">{title}</H3>
                <Body className={styles.aboutBodyText}>{description}</Body>
                <Link to={cta.url}>{cta.text}</Link>
              </Column>
            </Row>
          </Column>
        </Row>
      </Wrapper>
    </section>
  )
}

export default AboutSection
