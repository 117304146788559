import React from "react"
import Img, { FluidObject } from "gatsby-image"

import Column from "../Column"
import Link from "../Link"
import Row from "../Row"
import Wrapper from "../Wrapper"

import styles from "./index.module.scss"

type PropsType = {
  items: Array<{ img: FluidObject; textHtml: string; imgAlt: string }>
  cta: {
    text: string
    url: string
  }
}

const ServicesSection = ({ items, cta }: PropsType) => {
  return (
    <section className={styles.servicesSection}>
      <Wrapper>
        <Row spaceBetween>
          {items.map((item, i) => (
            <Column
              mobile={8}
              desktop={4}
              key={`home-img-${i}`}
              className={styles.illustrationContainer}
            >
              <Img
                fluid={item.img}
                alt={item.imgAlt}
                className={styles.illustration}
              />
              <div
                dangerouslySetInnerHTML={{ __html: item.textHtml }}
                className={styles.illustrationText}
              />
            </Column>
          ))}
        </Row>
        <Row center className={styles.ctaRow}>
          <Column mobile={8} desktop={5}>
            <Link variant="primary" to={cta.url} className={styles.cta}>
              {cta.text}
            </Link>
          </Column>
        </Row>
      </Wrapper>
    </section>
  )
}

export default ServicesSection
