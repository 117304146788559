import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SEO"

import Layout from "../components/layout"
import Hero from "../components/Hero"
import Services from "../components/ServicesSection"
import About from "../components/AboutSection"

import { FluidObject } from "gatsby-image"
import VolunteerSection from "../components/archived/components/VolunteerSection"

type Props = {
  data: {
    community: {
      childImageSharp: {
        fluid: FluidObject
      }
    }
    assist: {
      childImageSharp: {
        fluid: FluidObject
      }
    }
    support: {
      childImageSharp: {
        fluid: FluidObject
      }
    }
    area: {
      childImageSharp: {
        fluid: FluidObject
      }
    }
  }
}

const IndexPage = ({ data }: Props) => {
  const servicesProps = {
    items: [
      {
        img: data.community.childImageSharp.fluid,
        textHtml:
          "<p>We are a <strong>community-based organization</strong>.</p>",
        imgAlt: "four people icons surrounding a sprout",
      },
      {
        img: data.support.childImageSharp.fluid,
        textHtml:
          "<p>We are available<strong> 24 hours a day, 7 days a week.</strong>.</p>",
        imgAlt: "mobile phone with a speech bubble that says 24/7",
      },

      {
        img: data.assist.childImageSharp.fluid,
        textHtml:
          "<p>Our services are provided <strong>for free</strong> and <strong>to all genders</strong>.</p>",
        imgAlt: "two hands reaching towards each other",
      },
    ],
    cta: {
      text: "Learn more about our services",
      url: "/services",
    },
  }
  const aboutProps = {
    img: data.area.childImageSharp.fluid,
    imgAlt: "sun shining over sioux mountain on to a lake",
    title: "Where we work",
    description:
      "We service the Municipality of Sioux Lookout including Hudson and the three communities of Lac Seul First Nation: Frenchman’s Head, Kejick Bay and Whitefish Bay. ",
    cta: {
      text: "Learn more about our organization",
      url: "/about",
    },
  }
  const heroProps = {
    title: "Ah-shawah-bin Sioux Lookout / Lac Seul Victim Support Services",
    description:
      "Our mission is to provide client-driven and community-based victim supports and services to all in need.",
    banner: {
      title: "Find support and information",
      phone: "1-888-949-9300",
      cta: {
        text: "Other ways to contact us",
        url: "/contact",
      },
    },
  }

  // const volunteerProps = {
  //   description: "Looking to get involved?",
  //   title: "We’re looking for volunteers.",
  //   cta: {
  //     text: "Learn more about volunteering with us",
  //     url: "/volunteer",
  //   },
  // }
  return (
    <Layout>
      <SEO title="Home" />
      <Hero {...heroProps} />
      <Services {...servicesProps} />
      <About {...aboutProps} />
      {/* <VolunteerSection {...volunteerProps} /> */}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    community: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 200, maxHeight: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    support: file(relativePath: { eq: "support.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 200, maxHeight: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    assist: file(relativePath: { eq: "assist.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 200, maxHeight: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    area: file(relativePath: { eq: "area.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 200, maxHeight: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
