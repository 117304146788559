import React from "react"
import Column from "../Column"
import Link from "../Link"
import Row from "../Row"
import { Body, BodyLarge, BodySmall, H1 } from "../Typography"
import Wrapper from "../Wrapper"

import styles from "./index.module.scss"

type PropsType = {
  title: string
  description: string
  banner: {
    title: string
    phone: string
    cta: {
      text: string
      url: string
    }
  }
}

const Hero = ({ title, description, banner }: PropsType) => {
  return (
    <section className={styles.heroContainer}>
      <Wrapper className={styles.heroWrapper}>
        <Row center>
          <Column mobile={8} desktop={10}>
            <H1 className={styles.heroH1}>{title}</H1>
            <BodyLarge className={styles.heroBodyText}>{description}</BodyLarge>
            <div className={styles.banner}>
              <div>
                <BodySmall>{banner.title}</BodySmall>
                <BodyLarge fontWeight="bold">{banner.phone}</BodyLarge>
              </div>
              <Link
                to={banner.cta.url}
                iconId="arrowRightCircle"
                className={styles.bannerCta}
              >
                {banner.cta.text}
              </Link>
            </div>
          </Column>
        </Row>
      </Wrapper>
    </section>
  )
}

export default Hero
